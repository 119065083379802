@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.swiper-pagination-bullet {
    background-color: rgb(2, 155, 221) !important
}

.swiper-pagination-bullet-active {
    background-color: rgb(0, 17, 82) !important
}

.swiper-button-prev {
    color: rgb(2, 155, 221) !important;
    left: -5px !important;
    top: 25px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-size: 24px !important;
}

.swiper-button-next {
    color: rgb(2, 155, 221) !important;
    right: -5px !important;
    top: 25px !important;
}

.swiper-button-next::after,
.swiper-button-next::after {
    font-size: 24px !important;
}

body {
    font-family: "Montserrat", sans-serif !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timelineStart {
    height: 100px;
    width: 5px;
    background-color: #e5e5e5;
}

.timeline {
    height: 200px;
    width: 5px;
    background-color: #e5e5e5;
}

.stub1 {
    line-height: 300px;
    font-size: 24px;
    background-color: #eae4e4;
}

.stub2 {
    height: 1000px;
}

.circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    background-color: #e5e5e5;
}

.circleWrapper {
    position: relative;
}

.message {
    position: absolute;
    left: 160%;
    min-width: 400px;
    font-size: 14px;
    color: #A1A1A1
}

.messageTitle {
    position: absolute;
    top: -7px;
    right: 180%;
    min-width: 150px;
    font-weight: bold;
    font-size: 28px;
}

.triple-slider .triple-slider-prev,
.triple-slider .triple-slider-next {
    opacity: 100% !important;
}

.title-welcome-1 {
    animation: scaleText1 10s infinite;
    transform-origin: right;
}

.title-welcome-2 {
    animation: scaleText2 10s infinite;
    transform-origin: right;
}

.title-welcome-3 {
    animation: scaleText3 10s infinite;
    transform-origin: right;
}

.shadow-logo {
    filter: drop-shadow(0 0 5px #00000066);
}

@keyframes scaleText1 {
    0% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    2% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    32% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    34% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    100% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }
}

@keyframes scaleText2 {
    0% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    32% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    34% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    65% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    67% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    100% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }
}

@keyframes scaleText3 {
    0% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    2% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    65% {
        transform: scale(1);
        color: #C4C4C4;
        font-weight: 300;
    }

    67% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }

    100% {
        transform: scale(1.1);
        color: #029BDD;
        font-weight: bold;
    }
}

.reveal-left,
.reveal-right,
.reveal-middle,
.reveal-left2,
.reveal-right2,
.reveal-middle2 {
    animation: reveal-projects;
    animation-duration: 2s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.reveal-left {
    animation-delay: 400ms;
}

.reveal-right {
    animation-delay: 600ms;
}

.reveal-middle {
    animation-delay: 800ms;
}

.reveal-left2 {
    animation-delay: 800ms;
}

.reveal-right2 {
    animation-delay: 600ms;
}

.reveal-middle2 {
    animation-delay: 400ms;
}

@keyframes reveal-projects {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    50%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 719px) {
    .title-welcome-2 {
        animation: scaleText2 10s infinite;
        transform-origin: left;
    }

    .reveal-left,
    .reveal-right,
    .reveal-middle,
    .reveal-left2,
    .reveal-right2,
    .reveal-middle2 {
        animation: none;
        opacity: 100;
    }
}

@media screen and (max-width: 1024px) {
    .message {
        min-width: 140px;
    }

    .messageTitle {
        font-size: 18px;
        top: 0px
    }
}



/***** PLANET CSS ***/


.page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    position: relative;
    width: 540px;
    height: 540px;
}

.logo {
    position: absolute;
    left: calc((540px - 100px) / 2);
    top: calc((540px - 100px) / 2);
    width: 100px;
    height: 100px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.middle, .middle-uncolore,
.outer, .outer-uncolore,
.inDots, .inDots-uncolore,
.beforeOut, .beforeOut-uncolore {
    position: absolute;
    pointer-events: none;
}

.inDots, .inDots-uncolore {
    left: calc((540px - 150px) / 2);
    top: calc((540px - 150px) / 2);
    width: 150px;
    height: 150px;
    animation: rotate 35s linear infinite;
    background-color: rgba(2, 155, 221, 0.3);
    opacity: 30;
    z-index: 4;
    border-radius: 100%;
}

.inDots-uncolore {
    background-color: transparent;
}

.middle, .middle-uncolore {
    left: calc((540px - 250px) / 2);
    top: calc((540px - 250px) / 2);
    width: 250px;
    height: 250px;
    animation: rotate 35s linear infinite;
    opacity: 20;
    z-index: 3;
    background-color: rgba(2, 155, 221, 0.2);
}

.middle-uncolore {
    background-color: transparent;
}

.beforeOut, .beforeOut-uncolore {
    left: calc((540px - 345px) / 2);
    top: calc((540px - 345px) / 2);
    width: 345px;
    height: 345px;
    animation: rotate 35s linear infinite;
    opacity: 10;
    z-index: 2;
    background-color: rgba(2, 155, 221, 0.1);
    border-radius: 100%;
}

.beforeOut-uncolore {
    background-color: transparent;
}

.outer, .outer-uncolore {
    left: calc((540px - 440px) / 2);
    top: calc((540px - 440px) / 2);
    width: 440px;
    height: 440px;
    animation: rotate 35s linear infinite;
    opacity: 5;
    z-index: 1;
    background-color: rgba(2, 155, 221, 0.05);
}

.outer-uncolore {
    background-color: transparent;
}

.inner.dots, .inner-uncolore.dots,
.middle.dots, .middle-uncolore.dots,
.outer.dots, .outer-uncolore.dots,
.beforeOut.dots, .beforeOut-uncolore.dots {
    box-sizing: border-box;
    border-radius: 500px;
}


.dot {
    position: absolute;
    border-radius: 100px;
}

.dot img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.icon-inner-1-1 {
    height: 50px;
    width: 50px;
    left: 25px;
    bottom: 0px;
}

.icon-inner-1-2 {
    height: 50px;
    width: 50px;
    right: 25px;
    bottom: 0px;
}

.icon-inner-1-3 {
    height: 50px;
    width: 50px;
    left: 25px;
    top: 0px;
}

.icon-inner-1-4 {
    height: 50px;
    width: 50px;
    right: 25px;
    top: 0px;
}

.icon-inner-2-1 {
    height: 60px;
    width: 60px;
    right: 75%;
    bottom: 50px;
}

.icon-inner-2-2 {
    height: 60px;
    width: 60px;
    left: 75%;
    bottom: 50px;
}

.icon-inner-2-3 {
    height: 60px;
    width: 60px;
    left: 30%;
    bottom: -20px;
}

.icon-inner-2-4 {
    height: 60px;
    width: 60px;
    right: 30%;
    top: -20px;
}

.icon-outer-1-1 {
    height: 55px;
    width: 55px;
    left: 45%;
    top: 25px;
}

.icon-outer-1-2 {
    height: 55px;
    width: 55px;
    right: 25px;
    top: 45%;
}

.icon-outer-1-3 {
    height: 55px;
    width: 55px;
    left: 25px;
    top: 45%;
}

.icon-outer-1-4 {
    height: 55px;
    width: 55px;
    right: 45%;
    bottom: 25px;
}

.icon-outer-2-1 {
    height: 65px;
    width: 65px;
    left: -32px;
    bottom: 0px;
}

.icon-outer-2-2 {
    height: 65px;
    width: 65px;
    right: -32px;
    top: 0px;
}

.icon-outer-2-3 {
    height: 65px;
    width: 65px;
    left: -32px;
    top: 0px;
}

.icon-outer-2-4 {
    height: 65px;
    width: 65px;
    right: -32px;
    bottom: 0px;
}

.target {
    position: absolute;
    width: 64px;
    height: 64px;
    left: -32px;
    top: calc(50% - 32px);
    border-radius: 32px;
    pointer-events: auto;
    cursor: pointer;
}

.dot:nth-child(2) {
    left: calc(100% - 24px);
}

.target:nth-child(2) {
    left: calc(100% - 32px);
}

.inDots .dot, .inDots-uncolore .dot,
.inDots .target, .inDots-uncolore .target,
.middle .dot, .middle-uncolore .dot,
.middle .target, .middle-uncolore .target{
    animation: rotate 35s reverse linear infinite;
}

.beforeOut .dot, .beforeOut-uncolore .dot,
.beforeOut .target, .beforeOut-uncolore .target,
.outer .dot, .outer-uncolore .dot,
.outer .target, .outer-uncolore .target {
    animation: rotate 35s reverse linear infinite;
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.fade-in {
    animation: fadeIn 1s forwards;
}

.fade-out {
    animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        background-color: transparent;
    }

    to {
        opacity: 1;
        background-color: white;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        background-color: white;
    }

    to {
        opacity: 0;
        background-color: transparent;
    }
}

@media (max-width: 635px) {
    /* .container {
        position: relative;
        width: 300px;
        height: 300px;
    }

    .logo {
        position: absolute;
        left: calc((350px - 50px) / 2);
        top: calc((350px - 50px) / 2);
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background: linear-gradient(135deg, #3A8EF6, #6F3AFA);
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    /* .icon-inner-1-1 {
        height: 50px;
        width: 50px;
        left: 25px;
        bottom: 0px;
    }
    
    .icon-inner-1-2 {
        height: 50px;
        width: 50px;
        right: 25px;
        bottom: 0px;
    }
    
    .icon-inner-1-3 {
        height: 50px;
        width: 50px;
        left: 25px;
        top: 0px;
    }
    
    .icon-inner-1-4 {
        height: 50px;
        width: 50px;
        right: 25px;
        top: 0px;
    }
    
    .icon-inner-2-1 {
        height: 60px;
        width: 60px;
        right: 75%;
        bottom: 50px;
    }
    
    .icon-inner-2-2 {
        height: 60px;
        width: 60px;
        left: 75%;
        bottom: 50px;
    }
    
    .icon-inner-2-3 {
        height: 60px;
        width: 60px;
        left: 30%;
        bottom: -20px;
    }
    
    .icon-inner-2-4 {
        height: 60px;
        width: 60px;
        right: 30%;
        top: -20px;
    }
    
    .icon-outer-1-1 {
        height: 55px;
        width: 55px;
        left: 45%;
        top: 25px;
    }
    
    .icon-outer-1-2 {
        height: 55px;
        width: 55px;
        right: 25px;
        top: 45%;
    }
    
    .icon-outer-1-3 {
        height: 55px;
        width: 55px;
        left: 25px;
        top: 45%;
    }
    
    .icon-outer-1-4 {
        height: 55px;
        width: 55px;
        right: 45%;
        bottom: 25px;
    }
    
    .icon-outer-2-1 {
        height: 65px;
        width: 65px;
        left: -32px;
        bottom: 0px;
    }
    
    .icon-outer-2-2 {
        height: 65px;
        width: 65px;
        right: -32px;
        top: 0px;
    }
    
    .icon-outer-2-3 {
        height: 65px;
        width: 65px;
        left: -32px;
        top: 0px;
    }
    
    .icon-outer-2-4 {
        height: 65px;
        width: 65px;
        right: -32px;
        bottom: 0px;
    }    */

    .container {
        position: relative;
        width: 350px;
        height: 400px;
        overflow: hidden;
    }

    .logo {
        position: absolute;
        left: calc((350px - 50px) / 2);
        top: calc((350px - 50px) / 2);
        width: 50px;
        height: 50px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;
    }

    .middle, .middle-uncolore,
    .outer, .outer-uncolore,
    .inDots, .inDots-uncolore,
    .beforeOut, .beforeOut-uncolore {
        position: absolute;
        pointer-events: none;
    }

    .inDots, .inDots-uncolore {
        left: calc((350px - 80px) / 2);
        top: calc((350px - 80px) / 2);
        width: 80px;
        height: 80px;
        animation: rotate 35s linear infinite;
        background-color: rgba(2, 155, 221, 0.3);
        opacity: 30;
        z-index: 4;
        border-radius: 100%;
    }

    .inDots-uncolore {
        background-color: transparent;
    }

    .middle, .middle-uncolore {
        left: calc((350px - 175px) / 2);
        top: calc((350px - 175px) / 2);
        width: 175px;
        height: 175px;
        animation: rotate 35s linear infinite;
        opacity: 20;
        z-index: 3;
        background-color: rgba(2, 155, 221, 0.2);
    }

    .middle-uncolore {
        background-color: transparent;
    }

    .beforeOut, .beforeOut-uncolore {
        left: calc((350px - 250px) / 2);
        top: calc((350px - 250px) / 2);
        width: 250px;
        height: 250px;
        animation: rotate 35s linear infinite;
        opacity: 10;
        z-index: 2;
        background-color: rgba(2, 155, 221, 0.1);
        border-radius: 100%;
    }

    .beforeOut-uncolore {
        background-color: transparent;
    }

    .outer, .outer-uncolore {
        left: calc((350px - 310px) / 2);
        top: calc((350px - 310px) / 2);
        width: 310px;
        height: 310px;
        animation: rotate 35s linear infinite;
        opacity: 5;
        z-index: 1;
        background-color: rgba(2, 155, 221, 0.05);
    }

    .outer-uncolore {
        background-color: transparent;
    }

    .inner.dots, .inner-uncolore.dots,
    .middle.dots, .middle-uncolore.dots,
    .outer.dots, .outer-uncolore.dots,
    .beforeOut.dots, .beforeOut-uncolore.dots {
        box-sizing: border-box;
        border-radius: 500px;
    }


    .dot {
        position: absolute;
        border-radius: 100px;
    }

    .dot img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }

    .icon-inner-1-1 {
        height: 35px;
        width: 35px;
        left: 5px;
        bottom: 15px;
    }

    .icon-inner-1-2 {
        height: 35px;
        width: 35px;
        right: 5px;
        bottom: 15px;
    }

    .icon-inner-1-3 {
        height: 35px;
        width: 35px;
        left: 5px;
        top: 15px;
    }

    .icon-inner-1-4 {
        height: 35px;
        width: 35px;
        right: 5px;
        top: 15px;
    }

    .icon-inner-2-1 {
        height: 40px;
        width: 40px;
        right: 70%;
        bottom: 20px;
    }

    .icon-inner-2-2 {
        height: 40px;
        width: 40px;
        left: 75%;
        bottom: 20px;
    }

    .icon-inner-2-3 {
        height: 40px;
        width: 40px;
        left: 25%;
        bottom: -20px;
    }

    .icon-inner-2-4 {
        height: 40px;
        width: 40px;
        right: 25%;
        top: -20px;
    }

    .icon-outer-1-1 {
        height: 45px;
        width: 45px;
        left: 45%;
        top: 10px;
    }

    .icon-outer-1-2 {
        height: 45px;
        width: 45px;
        right: 10px;
        top: 45%;
    }

    .icon-outer-1-3 {
        height: 45px;
        width: 45px;
        left: 10px;
        top: 45%;
    }

    .icon-outer-1-4 {
        height: 45px;
        width: 45px;
        right: 45%;
        bottom: 10px;
    }

    .icon-outer-2-1 {
        height: 45px;
        width: 45px;
        left: -15px;
        bottom: 0px;
    }

    .icon-outer-2-2 {
        height: 45px;
        width: 45px;
        right: -15px;
        top: 0px;
    }

    .icon-outer-2-3 {
        height: 45px;
        width: 45px;
        left: -15px;
        top: 0px;
    }

    .icon-outer-2-4 {
        height: 45px;
        width: 45px;
        right: -15px;
        bottom: 0px;
    }

}

/****** Annuaire Bento ***/


.activity-container {
    display: grid;
    /* grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 150px);
     */
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 225px); */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 225px);
    column-gap: 15px;
    row-gap: 5px;
}

.img-one {
    grid-area: 1 / 1 / 2 / 2;
}

.img-two {
    grid-area: 2 / 1 / 3 / 2;
}

.img-three {
    display: block;
    grid-area: 1 / 2 / 3 / 4;
}

.img-four {
    grid-area: 1 / 4 / 2 / 5;
}

.img-five {
    /* grid-area: 1 / 5 / 2 / 6; */
    grid-area: 2 / 4 / 3 / 5;
}

.img-six {
    display: block;
    grid-area: 2 / 4 / 3 / 6;
}

.image-container {
    position: relative;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
    border-radius: 10px;
}

@media (min-width: 1440px) {
    .activity-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 300px);
    }
}

@media (max-width: 1150px) {

    .activity-container {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 150px);
    }

    .img-one {
        grid-area: 1 / 1 / 2 / 2;
    }

    .img-two {
        grid-area: 2 / 1 / 3 / 2;
    }

    .img-three {
        grid-area: 2 / 2 / 3 / 4;
    }

    .img-four {
        grid-area: 1 / 2 / 2 / 3;
    }

    .img-five {
        grid-area: 1 / 3 / 2 / 4;
    }

    .img-six {
        grid-area: 2 / 2 / 3 / 4;
    }

}


@media (max-width: 910px) {
    .activity-container {
        display: flex;
        flex-direction: column;
    }

    .img-one, .img-two, .img-three, .img-four, .img-five, .img-six {
        height: 150px;
        width: 100%
    }
}